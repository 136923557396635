
import { defineAsyncComponent, defineComponent } from 'vue';

/** Utils */
import { listTypeslogin, listCountrys } from '@/utils/auth.utils';
import { showSwalInfo, showSwalError } from '@/utils/sweetalert2-utils';
import { showErrors } from '@/utils/general-utils';
import Swal from 'sweetalert2';

/** Interfaces */
import { LoginI, SendSmsI } from '@/interfaces/auth.interface';

/** reCAPTCHA */
import { renderReCaptchaWidget } from '@artifact-project/recaptcha';

/** Components */
import CookieConsent from '@/components/layout/CookieConsent.vue';
import { Field, Form } from 'vee-validate';

/** Validations */
import { schemaLoginForm } from '@/validations/login-validation';

export default defineComponent({
  name: 'Login',
  props: {
    token: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    ModalConfirmSms: defineAsyncComponent(
      () => import('@/components/shared/ModalConfirmSms.vue')
    ),
    Form,
    Field,
    CookieConsent,
  },
  data() {
    const dataForm: SendSmsI = {};

    return {
      isActive: false,
      selectTypes: listTypeslogin,
      selectCountry: listCountrys,
      schemaLoginForm: schemaLoginForm,
      selectCountryDefault: listCountrys[0].value,
      selectDefault: listTypeslogin[0].value,
      successReCaptcha: false,
      showModalSms: false,
      dataForm: dataForm,
      showRecaptcha: process.env.VUE_APP_SHOW_RECAPTCHA === 'true',
      sendingLoading: false,
      sendingSuccess: false,
      loginLoading: false,
    };
  },
  methods: {
    sendSms(form: SendSmsI) {
      form = {
        ...form,
        country_code: this.selectCountryDefault,
      };
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - ingresar',
        type: 'custom',
        payload: form,
      });
      this.sendingLoading = true;
      this.$store
        .dispatch('sendSms', form)
        .then((res) => {
          this.$store.dispatch('addTransaction', res.data);
          const { success, token = false } = res.data;
          if (success) {
            if (!token) {
              this.dataForm = form;
              this.openModalConfirmSms();
              this.sendingSuccess = true;
              setTimeout(() => (this.sendingSuccess = false), 4000);
            } else {
              showSwalInfo(
                `Se ha enviado un email a su correo para habilitar el número ${form?.phone_number} para acceder a la Sucursal Virtual.`
              );
            }
          } else {
            showSwalError(
              'No fue posible enviar el mensaje, intente más tarde.'
            );
          }
        })
        .catch((error) => {
          if (
            error.response.status === 422 &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              'error'
            ) &&
            Object.prototype.hasOwnProperty.call(
              error.response.data.error,
              'link'
            )
          ) {
            this.openModalWhatAppLink(
              error.response.data.error.title,
              error.response.data.error.message[0],
              error.response.data.error.link
            );
          } else {
            showErrors(
              error,
              'No fue posible enviar el mensaje, intente más tarde.',
              true
            );
          }
        })
        .finally(() => {
          this.sendingLoading = false;
          this.$store.dispatch('endTransactionApm');
        });
    },

    resendSms() {
      this.sendSms(this.dataForm);
    },

    login(form: LoginI) {
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - aceptar-otp',
        type: 'custom',
        payload: form,
      });
      this.loginLoading = true;
      this.$store
        .dispatch('login', form)
        .then(() => this.$router.push({ name: 'Home' }))
        .catch((error) =>
          showErrors(error, 'Se produjo un error al iniciar sesión.', true)
        )
        .finally(() => {
          this.loginLoading = false;
          this.$store.dispatch('endTransactionApm');
        });
    },

    closeModalConfirmSms() {
      this.showModalSms = false;
    },

    openModalConfirmSms() {
      this.showModalSms = true;
    },

    openModalWhatAppLink(title: string, message: string, link: string) {
      Swal.fire({
        title: title,
        html: `<br /> ${message}`,
        icon: 'info',
        showCancelButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'Cerrar',
        cancelButtonColor: '#A3053D',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.replace(link);
        }
      });
    },
  },
  created() {
    if (this.token) {
      this.$store.dispatch('startTransactionApm', {
        apm: this.$apm,
        name: 'click - validar-nuevo-registro-plataforma',
        type: 'custom',
        payload: {},
      });
      this.$store
        .dispatch('validateTokenSucursal', this.token)
        .then(() => this.$router.push({ name: 'Home' }))
        .catch((error) => {
          showErrors(error, 'Se produjo un error al iniciar sesión.', true);
        })
        .finally(() => this.$store.dispatch('endTransactionApm'));
    }
  },
  mounted() {
    renderReCaptchaWidget({
      el: document.getElementById('recaptcha') as HTMLElement,
      params: {
        sitekey: process.env.VUE_APP_KEY_RECAPTCHA,
        lang: 'es_ES',
        theme: 'light',
        type: 'image',
      },
      handle: (type) => {
        this.successReCaptcha = type?.toLowerCase() === 'change';
      },
    });
  },
});
