export const listTypeslogin = [
  {
    value: 'CC',
    text: 'Cédula de ciudadanía',
  },
  {
    value: 'CE',
    text: 'Cédula de extranjería',
  },
  {
    value: 'NIT',
    text: 'NIT',
  },
];

export const listCountrys = [
  {
    value: '+57',
    text: 'Colombia',
  },
  {
    value: '+51',
    text: 'Perú',
  },
];
