import { object, string, boolean } from 'yup';

const regexIdentification = /^(\d{4,10})*$/;

export const schemaLoginForm = object().shape({
  document_type: string()
    .oneOf(['CC', 'CE', 'NIT'], 'El tipo de identificación no es válido.')
    .trim()
    .required('El tipo de documento es requerido.'),
  identification: string()
    .trim()
    .matches(regexIdentification, 'El número de documento no es válido.')
    .min(4, 'mínimo 4 caracteres.')
    .max(10, 'máximo 10 caracteres.')
    .required('El número de documento es requerido.'),
  phone_number: string()
    .trim()
    .matches(/^3\d{9}$/, 'El número de celular no es válido.')
    .min(10, 'mínimo 10 caracteres.')
    .max(10, 'máximo 10 caracteres.')
    .required('El número de celular es requerido.'),
  checkbox: boolean().oneOf([true], 'por favor acepte terminos y condiciones.'),
  captcha: boolean().oneOf([true], 'Por favor complete el captcha'),
});
